@import "config";

@import "../config/index";


.button {
    display: inline-flex;
    box-sizing: border-box;
    border: 0;
    padding: $button-padding;
    margin: $button-margin;
    background-color: $color-button-bg;
    color: $color-button-text;
    font-size: $font-size-submit-button;
    font-weight: $font-weight-bold;
    cursor: pointer;
    text-decoration: none;
    text-shadow: none;
}

.button--search {
    margin: 0;
}
