@import "config";


.tag {
    display: inline-block;
    padding: $tag-padding;
    background-color: $tag-bg-color;
    color: $tag-color;
    text-decoration: none;
    border-radius: 3px;
    margin: 5px 5px 5px 0;

    &:last-child {
        margin-right: 0;
    }
}

.tag--remove {
    &:after {
        @include icon-font();
        content: "x";
        float: right;
        margin-left: 10px;
    }
}

.tag--clear-all {
    background-color: $clear-all-tag-bg-color;
    color: $clear-all-tag-color;
}

.tag__container {
    width: 100%;
    margin: 20px 0;
}

.tag__container--centred {
    text-align: center;
}
