@import "../config/colors";

// Dimensions
$input-column-padding: 10px;
$label-padding: 20px 0 5px 0;
$input-padding: 5px;

// Fonts
$font-size-labels: 1rem;
$font-size-inputs: 1.5rem;

// Colours
$color-control-border: $color-white;
$color-error: $color-red;
