@import "config";

.list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.list__item {
    display: flex;
    margin: 10px 0;
}

.list__rating {
    margin-right: 10px;
}
