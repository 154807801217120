@import "../config/index";

@import "../Header/config";
@import "../Footer/config";

@import "config";


p {
    margin: 0 0 0.5em;
    padding: 0;
    line-height: 1.5;
}

.page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-width: $mobile-width-min;

    font-family: $font-family-default;
    font-weight: $font-weight-normal;
    font-size: $font-size-content;
}

.page__header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: $header-height;
    background-color: $color-header-bg;
}

.page__main {
    margin-top: $header-height;
}

.page__footer {
    height: $footer-height;
    box-sizing: border-box;
    background-color: $color-footer-bg;
    flex: 1;
}
