@import "config";
@import "../config/index";

@import "../Field/config";
@import "../Header/config";


.search {
    display: flex;
    flex-wrap: nowrap;
    flex: 1 1;
    justify-content: flex-end;
}

.search__input {
    border: none;
    padding: $input-padding;
    font-size: $font-size-inputs;
    flex: 1 1;
    min-width: 0;
    box-sizing: border-box;
}

.search__input--expandable {
    position: absolute;
    width: $search-input-width;
    height: $search-height;

    left: calc(100% - #{($search-button-width + $search-input-width + $search-padding)});
    right: ($search-button-width + $search-padding);
    bottom: $search-padding;
    transition: width 1s, left 1s;
    box-sizing: border-box;

    @media #{$screen-tablet} {
        width: auto;
        position: static;
        transition: none;
    }

    &:focus {
        width: calc(100% - #{($search-button-width + 2*$search-padding)});
        left: $search-padding;
    }
}

.search__button {
    position: absolute;
    width: $search-button-width;
    height: $search-height;
    bottom: $search-padding;
    right: $search-padding;
    box-sizing: border-box;
    border: none;
    background-color: $color-white;
    color: $color-base;
    font-size: $font-size-inputs;
    text-align: center;
    cursor: pointer;

    @media #{$screen-tablet} {
        position: static;
    }
}
