@import "../config/index";

@import "config";


.heading {
    display: block;
    flex-basis: 100%;
    font-size: $font-size-default-heading;
    font-weight: $font-weight-bold;
    font-family: $font-family-headings;
    margin: 0.25em 0;
    @media #{$screen-tablet} {
        font-size: $font-size-default-heading-tablet;
    }
}

.heading--subtitle {
    font-size: $font-size-subtitle;
    font-weight: $font-weight-bold;
    @media #{$screen-tablet} {
        font-size: $font-size-subtitle-tablet;
    }
}

.heading--major {
    font-size: $font-size-major-heading;
    @media #{$screen-tablet} {
        font-size: $font-size-major-heading-tablet;
    }
}

.heading--minor {
    font-size: $font-size-minor-heading;
    font-weight: $font-weight-bold;
    @media #{$screen-tablet} {
        font-size: $font-size-minor-heading-tablet;
    }
}

.heading--centred {
    text-align: center;
}

.heading--collapsible {
    margin: 0;
}
