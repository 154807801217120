@import "config";
@import "../config/colors";


.image {
    /* Vertically centre alt text */
    display: flex;
    align-items: center;
    max-width: 100%;
    height: auto;
    width: auto;
}

.image--scaled {
    width: auto;
    height: auto;
}

.image--centred {
    margin: 0 auto;
}


.image__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.image__container--top {
    @media #{$screen-full-width} {
        align-items: flex-start;
    }
}

.image__container--autocrop {
    max-height: 320px;
    overflow: hidden;
}
