@import "../config/index";

@import "config";


.header {
    height: $header-height;
    max-width: $desktop-width;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    background-color: $color-header-bg;
    color: $color-header-text;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    .link {
        color: $color-header-link;
        font-weight: $font-weight-bold;
    }
}

.header__title {
    height: $header-title-height;
    padding: 0 $header-lr-padding;
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    margin: 10px 0;
    order: 1;
    font-family: $font-family-headings;
    font-size: 1.75rem;
}

.header__logo {
    padding: 5px 0;
    display: inline-flex;
    align-items: center;
}

.header__logo-image {
    margin-right: 15px;
    min-width: 60px;
}

.header__logo-text {
    white-space: nowrap;
}

.header__nav {
    height: $header-nav-height;
    padding: 0 $header-lr-padding;
    text-align: right;
    background-color: $color-teal;
    align-items: center;
    white-space: nowrap;

    display: flex;
    flex: 1 1;
    order: 2;
}

.header__search {
    min-width: $header-search-width;
    padding: 5px;
    display: flex;
    flex: 0 1;
    order: 3;
    align-items: center;
    height: $header-nav-height;
    box-sizing: border-box;
    background-color: $color-teal;

    @media #{$screen-tablet} {
        flex-basis: $header-search-width-tablet;
    }
    @media #{$screen-desktop} {
        flex-basis: $header-search-width-desktop;
    }

}
