@import "../config/colors";

// Dimensions
$footer-height: 160px;
$footer-tb-padding: 60px;
$footer-padding: $footer-tb-padding $page-lr-padding;
$subfooter-top-padding: 20px;

// Colours
$color-footer-bg: $color-dark-grey;
$color-footer-text: $color-grey;
$color-footer-link: $color-white;
