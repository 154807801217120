@import "config";

.social-bar {
    padding: 0;
    margin: 0;
    list-style: none;
}

.social-bar__item {
    display: inline;
    margin-right: 10px;
    padding: 0;
}
