@import "../config/index";

@import "config";


.footer {
    min-height: $footer-height;
    padding: $footer-padding;
    box-sizing: border-box;
    background-color: $color-footer-bg;
    color: $color-footer-text;

    .link {
        color: $color-footer-link;
        font-weight: $font-weight-bold;
    }
}

.footer__main {
    width: 100%;
    max-width: $desktop-width;
    margin: 0 auto;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer__subfooter {
    width: 100%;
    max-width: $desktop-width;
    margin: $subfooter-top-padding auto 0 auto;
    font-size: $font-size-smallprint;
    text-transform: uppercase;

    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
}
