@import "../config/index";

@import "../Page/config";

@import "config";


.nav-menu {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: left;
}

.nav-menu__item {
    padding: 0 0 5px 0;
}

.nav-menu--header {
    margin: 0;
    text-transform: uppercase;
    font-size: $font-size-header-nav;

    .nav-menu__item {
        display: inline-block;
        padding: $nav-item-padding;
        text-align: center;
        margin-left: $nav-item-spacing;
        border-top: solid $nav-item-border-width transparent;
        border-bottom: solid $nav-item-border-width transparent;

        @media #{$screen-desktop} {
            margin-left: $nav-item-spacing-desktop;
        }

        &:first-child {
            margin-left: 0;
        }

        &.nav-menu__item--active {
            border-bottom: solid $nav-item-border-width $color-white;
        }

        &:hover {
            border-bottom: solid $nav-item-border-width $color-white;
        }
    }

}

.nav-menu--footer {
    width: auto;
    font-size: $font-size-smallprint;
    margin-right: 5px;

    .nav-menu__item {
        display: inline;
        padding: 0;
        &:not(:first-child)::before {
            content: "\00a0|\00a0";
        }
    }
}
