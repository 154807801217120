@import "../config/colors";

@import "config";


.share-bar {
    font-size: 2rem;
}

.share-bar__item {
    display: inline-block;
    margin: 2px 5px;
    text-decoration: none;
    color: $color-link;
    outline: 0;

    &:active {
        border: none;
    }
}
