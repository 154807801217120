@import "../config/index";


.togglable {
    cursor: pointer;
}

.togglable--on {
    &:after {
        @include icon-font();
        margin-left: 0.1em;
        content: "e";
    }
}

.togglable--off {
    &:after {
        @include icon-font();
        margin-left: 0.1em;
        content: "d";
    }
}
