@import "../config/index";

@import "config";


.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 20px 0;
    padding: 0;
}

.pagination__item {
    margin-left: -1px;

    ::first-child & {
        margin-left: 0;
    }
}

.pagination__link {
    padding: 0.5rem 0.75rem;
    border: solid 2px $color-white;
    border-radius: 5px;
    background-color: $color-xlight-grey;
    color: $color-link;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.5s, color 0.5s;

    &:hover {
        background-color: $color-teal;
        color: $color-white;
    }

}

.pagination__link--disabled {
    cursor: default;
    background-color: $color-xlight-grey;
    color: $color-tint-grey;

    &:hover {
        background-color: $color-xlight-grey;
        color: $color-tint-grey;
    }
}

.pagination__link--active {
    background-color: $color-teal;
    color: $color-white;

    &:hover {
        background-color: $color-teal;
        color: $color-white;
    }
}
