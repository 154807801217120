@import "config";


.field {
    width: 100%;
    box-sizing: border-box;
    padding: $input-column-padding;
}

.field--error {
    color: $color-error;
}

.field__label {
    display: block;
    margin: $label-padding;
    font-size: $font-size-labels;
    text-transform: uppercase;
}

@mixin form-control() {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: $input-padding;
    border: solid 2px $color-control-border;
    font-size: $font-size-inputs;
}

.field__input {
    @include form-control();
}

.field__textarea {
    @include form-control();
}
