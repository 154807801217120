@import "../config/index";
@import "../Heading/config";

@import "config";


.section {
    padding: $section-padding;
    background-color: $color-alpha-bg;
    color: $color-alpha-text;
    border-color: $color-alpha-border;
}

.section--alpha {
}

.section--beta {
    background-color: $color-beta-bg;
    color: $color-beta-text;
    border-color: $color-beta-border;
}

.section--gamma {
    background-color: $color-gamma-bg;
    color: $color-gamma-text;
    border-color: $color-gamma-border;
}

.section--filters {
    border-width: 3px;
    border-top-style: solid;
    border-bottom-style: solid;
}


@mixin section-bg-image($background-position-x: center, $background-position-y: center) {
    padding: 40px 0;
    box-sizing: border-box;
    background-color: $color-scheme-image-bg;
    background-size: cover;
    background-position-y: $background-position-y;
    background-position-x: $background-position-x;
    color: $color-scheme-image-text;
    border-color: $color-scheme-image-border;

    // Weird flicker fix for chrome:
    -webkit-backface-visibility: hidden;

    display: flex;
    align-items: center;

    @media #{$screen-tablet} {
        padding: 100px 0;
    }
}

@mixin section-title($background-position-x: center, $background-position-y: center) {
    @include section-bg-image($background-position-x, $background-position-y);
    // Full viewport height minus the header
    min-height: calc(80vh - #{$header-height});
}

.section--title {
    @include section-title();

    background-image: url("../../img/backgrounds/carcassonne-580.jpg");
    @media #{$screen-retina} {
        background-image: url("../../img/backgrounds/carcassonne-1170.jpg");
    }

    @media #{$screen-tablet} {
        background-image: url("../../img/backgrounds/carcassonne-800.jpg");
    }
    @media #{$screen-tablet-retina} {
        background-image: url("../../img/backgrounds/carcassonne-1600.jpg");
    }

    @media #{$screen-desktop} {
        background-image: url("../../img/backgrounds/carcassonne-1170.jpg");
    }
    @media #{$screen-desktop-retina} {
        background-image: url("../../img/backgrounds/carcassonne.jpg");
    }

    @media #{$screen-desktop-full} {
        background-image: url("../../img/backgrounds/carcassonne.jpg");
    }
}

.section--image-background {
    @include section-bg-image();
    // 80% of viewport height
    min-height: 80vh;
    height: auto;
}

.section--banner-image-background {
    @include section-bg-image();
    // 20% of viewport height
    min-height: 30vh;
    height: auto;
    background-position-y: center;
    background-position-x: center;
}

.section__header {
    max-width: $grid-full-width + 2*$section-header-lr-padding;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

.section__main {
    max-width: $grid-full-width + 2*$section-main-lr-padding;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

.section__main--padded {
    padding: 15px 0;
}

.section__footer {
    max-width: $grid-full-width + 2*$section-footer-lr-padding;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

.section__content {
    padding: $section-content-padding;
}
