@import "config";

.option-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: $option-list-border-tb;
}

.option-list__item {
    width: 100%;
}

.option-list__item-inner {
    display: block;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;

    .option-list__item--selected & {

    }
}
