// Responsive Sizes

$mobile-width-min: 320px;
$tablet-width-min: 580px;
$desktop-width-min: 800px;
$desktop-width: 1170px;

$screen-tablet: "screen and (min-width: "$tablet-width-min")";
$screen-desktop: "screen and (min-width: "$desktop-width-min")";
$screen-desktop-full: "screen and (min-width: "$desktop-width")";

$screen-retina: "screen and (min-resolution: 200dpi)";
$screen-tablet-retina: "#{$screen-tablet} and (min-resolution: 200dpi)";
$screen-desktop-retina: "#{$screen-desktop} and (min-resolution: 200dpi)";
$screen-desktop-full-retina: "#{$screen-desktop-full} and (min-resolution: 200dpi)";

// Grids
$grid-full-width: $desktop-width;
$screen-full-width: "screen and (min-width: "$grid-full-width")";

$grid-half-width: calc($desktop-width / 2);
$screen-half-width: "screen and (min-width: "$grid-half-width")";

$grid-third-width: calc($desktop-width / 3);
$screen-third-width: "screen and (min-width: "$grid-third-width")";

$grid-two-thirds-width: $grid-third-width * 2;
$screen-two-thirds-width: "screen and (min-width: "$grid-two-thirds-width")";

$grid-quarter-width: calc($desktop-width / 4);
$screen-quarter-width: "screen and (min-width: "$grid-quarter-width")";

$grid-three-quarters-width: $grid-quarter-width * 3;
$screen-three-quarters-width: "screen and (min-width: "$grid-three-quarters-width")";
