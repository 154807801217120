@import "config";

.rating {
    font-family: icons;
    text-transform: none;
    display: inline-flex;
}


@for $rating from 1 to 10 {

    .rating--#{$rating} {
        :nth-child(n) {
            color: $color-star;
            &::before {
                content: "c"
            }
        }

        @if $rating % 2 == 1 {
            :nth-child(#{calc(calc($rating - 1)/2) + 1}) {
                /* Odd (half star) */
                &::before {
                    content: "b"
                }
            }
            :nth-child(n + #{calc(calc($rating + 1)/2) + 1}) {
                /* Unfilled stars */
                color: $color-unfilled-star;
                &::before {
                    content: "a"
                }
            }
        } @else {
            :nth-child(n + #{calc($rating/2) + 1}) {
                /* Unfilled stars */
                color: $color-unfilled-star;
                &::before {
                    content: "a"
                }
            }
        }
    }

}
