// Colours

// Brand Colours
$color-white: #ffffff;
$color-pale: #f4f5f6;
$color-navy: #254558;
$color-sky-blue: #1ed2f4;
$color-teal: #06a77d;
$color-green: #66aa66;

$color-xdark-grey: #080808;
$color-dark-grey: #1d1d1b;
$color-medium-grey: #333333;
$color-grey: #636362;
$color-tint-grey: #a0a0a0;
$color-light-grey: #e0e0e0;
$color-xlight-grey: #eeeeee;
$color-xxlight-grey: #fafaf8;
$color-black: #000;

// Utility Colours
$color-yellow: #eebb00;
$color-red: #ff0000;
$color-transparent-warm: rgba(120, 27, 10, 0.3);
$color-transparent-grey: rgba($color-dark-grey, 0.3);
$color-transparent-grey-dark: rgba($color-dark-grey, 0.9);
$color-transparent-white: rgba($color-white, 0.8);
$color-drop-shadow: rgba($color-dark-grey, 0.5);

// Abstraction
$color-background: $color-white;
$color-background-2: $color-dark-grey;
$color-background-3: $color-pale;
$color-base: $color-navy;
$color-accent: $color-sky-blue;
$color-accent-2: $color-teal;

$color-primary: $color-background;
$color-secondary: $color-base;
$color-tertiary: red;

$color-text: $color-medium-grey;
$color-text-inverse: $color-white;
$color-link: $color-accent;
$color-link-inverse: $color-white;

// Colour Schemes
// Alpha (Main Content)
$color-alpha-bg: $color-primary;
$color-alpha-text: $color-text;
$color-alpha-heading: $color-text;
$color-alpha-border: $color-accent;
$color-alpha-link: $color-accent;

// Beta (Boxes etc)
$color-beta-bg: $color-background-2;
$color-beta-text: $color-text-inverse;
$color-beta-heading: $color-text-inverse;
$color-beta-border: $color-accent;
$color-beta-link: $color-accent;

// Gamma (Filters etc)
$color-gamma-bg: $color-background-3;
$color-gamma-text: $color-text;
$color-gamma-heading: $color-text;
$color-gamma-border: $color-accent;
$color-gamma-link: $color-accent;

// Delta (Buttons etc)
$color-delta-bg: $color-secondary;
$color-delta-text: $color-tertiary;
$color-delta-heading: $color-tertiary;
$color-delta-border: $color-text-inverse;
$color-delta-link: $color-tertiary;

// Omega (Header / Footer)
$color-omega-bg: $color-base;
$color-omega-text: $color-text-inverse;
$color-omega-heading: $color-text-inverse;
$color-omega-border: $color-text-inverse;
$color-omega-link: $color-text-inverse;

// Colours to show on top of an image background
$color-scheme-image-bg: $color-dark-grey;
$color-scheme-image-text: $color-dark-grey;
$color-scheme-image-heading: $color-white;
$color-scheme-image-border: $color-white;
$color-scheme-image-link: $color-yellow;
