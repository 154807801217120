@import "../config/colors";
@import "../Page/config";

// Dimensions
$header-height: 150px;
$header-lr-padding: $page-lr-padding;
$header-padding: $page-tb-padding $header-lr-padding;
$header-nav-height: 60px;
$header-title-height: $header-height - $header-nav-height - 2*$page-tb-padding;

$header-search-width: 50px;
$header-search-width-tablet: 300px;
$header-search-width-desktop: 400px;

// Colours
$color-header-bg: $color-omega-bg;
$color-header-text: $color-omega-text;
$color-header-link: $color-omega-link;
