// Typography

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

@font-face {
  font-family: "icons";
  src: url("../../fonts/Icons/icons.eot"),
       url("../../fonts/Icons/icons.woff") format("woff"),
       url("../../fonts/Icons/icons.ttf") format("truetype"),
       url("../../fonts/Icons/icons.svg") format("svg");
  font-weight: $font-weight-normal;
  font-style: normal;
}

// Roboto
@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto/Roboto-Regular.ttf");
  font-weight: $font-weight-normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto/Roboto-Bold.ttf");
  font-weight: $font-weight-bold;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto/Roboto-Italic.ttf");
  font-weight: $font-weight-normal;
  font-style: italic;
}

// Yeon Sung
@font-face {
  font-family: "Yeon Sung";
  src: url("../../fonts/Yeon_Sung/YeonSungEn-Regular.ttf");
  font-weight: $font-weight-normal;
  font-style: normal;
}


$font-family-default: 'Roboto', sans-serif;
$font-family-headings: 'Yeon Sung', sans-serif;
$font-family-brand: 'Yeon Sung', sans-serif;

$font-size-content: 1.25rem;
$font-size-smallprint: 0.75rem;

@mixin icon-font() {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    font-family: icons;
    text-transform: none;
    text-rendering: auto;
    margin: 0.175em 0 0 0;
    padding: 0;
}
