@import "../config/index";

@import "config";


.segment {
    margin: 0;
    padding: $segment-padding;
    box-sizing: border-box;
}

.segment__wrapper {
    max-width: $grid-full-width;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
}

.segment__wrapper--distributed {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.segment__wrapper--centred {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.segment__wrapper--filled {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.segment__wrapper--narrow {
    max-width: $grid-two-thirds-width;
    margin: 0 auto;
}

.segment__wrapper--grid {
    justify-content: center;
}

.segment__wrapper--right {
    justify-content: end;
}

.segment__wrapper--shade {
    background-color: $color-transparent-grey-dark;
    color: $color-text-inverse;
}

.segment__inner {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .segment--framed & {
        border: solid 3px;
    }

    .segment--alpha & {
        background-color: $color-alpha-bg;
        border-color: $color-alpha-border;
        color: $color-alpha-text;
        box-shadow: 0 0 10px $color-transparent-grey;
    }

    .segment--beta & {
        background-color: $color-beta-bg;
        border-color: $color-beta-border;
        color: $color-beta-text;
        box-shadow: 0 0 10px $color-transparent-grey;
    }

    .segment--gamma & {
        background-color: $color-gamma-bg;
        border-color: $color-gamma-border;
        color: $color-gamma-text;
        box-shadow: 0 0 10px $color-transparent-grey;
    }

    .segment--shade & {
        background-color: $color-transparent-grey-dark;
    }

    .segment--bright & {
        background-color: $color-transparent-white;
    }
}

.segment__inner--vertically-centred {
    justify-content: center;
}


.segment__content {
    padding: $segment-content-padding;
}

.segment__content--centred {
    text-align: center;
}

.segment__content--vertically-padded {
    padding-top: 20px;
    padding-bottom: 20px;
}


.segment--grid-full-width {
    width: $grid-full-width;
    flex-basis: 100%;
    flex-grow: 12;
}

.segment--grid-half-width {
    flex-basis: 100%;
    flex-grow: 6;
    flex-shrink: 1;

    @media #{$screen-full-width} {
        flex-basis: $grid-half-width;
        flex-grow: 0;
    }
}

.segment--grid-third-width {
    flex-basis: 100%;
    flex-grow: 4;
    flex-shrink: 1;

    @media #{$screen-two-thirds-width} {
        flex-basis: 50%;
        flex-grow: 1;
    }
    @media #{$screen-full-width} {
        flex-basis: $grid-third-width;
        flex-grow: 0;
    }
}

.segment--grid-two-thirds-width {
    flex-grow: 8;
    flex-shrink: 1;
    flex-basis: 100%;

    @media #{$screen-full-width} {
        flex-basis: $grid-two-thirds-width;
        flex-grow: 0;
    }
}

.segment--grid-quarter-width {
    flex-grow: 3;
    flex-shrink: 1;

    @media #{$screen-half-width} {
        flex-basis: 50%;
        flex-grow: 0;
    }
    @media #{$screen-full-width} {
        flex-basis: $grid-quarter-width;
        flex-grow: 0;
    }
}

.segment--grid-three-quarters-width {
    flex-grow: 9;
    flex-shrink: 1;
    flex-basis: 100%;

    @media #{$screen-full-width} {
        flex-basis: $grid-three-quarters-width;
        flex-grow: 0;
    }
}

.segment--wide {
    padding: $segment-wide-padding;
}

.segment--centred {
    margin: 0 auto;
    align-self: center;
}

.segment--figure {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.segment--compact {
    padding: 0 $segment-lr-padding;
}

.segment--heading {
    padding: 10px $segment-lr-padding 0;
}

.segment__header {
    box-sizing: border-box;
}

.segment__main {
    box-sizing: border-box;
    padding: 10px 0 5px;
}

.segment__main--columns {
    column-gap: 20px;
    column-count: 3;
    column-width: $grid-third-width - 40px;
    column-rule: 1px dotted $color-grey;
    p {
        break-inside: avoid;
    }
}

.segment__main--centred {
    text-align: center;
    justify-content: center;
}

.segment__footer {
    box-sizing: border-box;
    margin-top: auto;
}
